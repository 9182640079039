const theme = {
  breakpoints: ['480px', '650px', '1000px', '1200px', '1400px'],
  fontSizes: ['1rem', '1.2rem', '1.44rem', '1.728rem', '2.074rem', '2.488rem','3.182rem','4.182rem'],
  colors: {
    // jaune pale qui va bien : faeb9e
    primary: '#C4D449',
     //vert fluo  #a4ce3b
 // bleu foncé
   // violet #494992
 //  secondary: '#023b59', //bleu sombre qui va bien 
  //  secondary: '#639630', // vert foncé theme
    //secondary: '#482673', //violet qui va bien
    secondary: '#5e371b', // marron du logo
    black: '#0d0d0d',
    shade: '#f5f5f5',
    white: '#fff',
    orange: '#EF8417' , // orange
    violetfluo: '#c41df2' , // violet fluo
    violet: '#482673', // violet qui va bien
    marron: '#734014' , // marron pas top
    
    bleuflash:'#11cbf2',
  },
  space: [
    '0',
    '0.25rem',
    '0.5rem',
    '0.75rem',
    '1rem',
    '1.25rem',
    '1.5rem',
    '2rem',
    '2.5rem',
    '3rem',
    '4rem',
    '6rem',
    '8rem',
    '12rem',
    '16rem',
  ],
  fontWeights: {
    normal: 400,
    bold: 700,
  },
  sidebarWidth: {
    big: '375px',
    normal: '320px',
  },
}

export default theme