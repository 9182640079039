import styled from 'styled-components'
import { animated } from 'react-spring'

import Link from '../components/localizedLink'
import {
  space,
  color,
  width,
  flex,
  alignSelf,
  flexWrap,
  flexDirection,
  alignItems,
  justifyContent,
  textAlign,
} from 'styled-system'

export const Box = styled.div`
  box-sizing: border-box;
  ${space}
  ${width}
  ${color}
  ${flex}
  ${alignSelf}
  ${textAlign}
`

Box.displayName = 'Box'

export const AnimatedBox = styled(animated.div)`
  box-sizing: border-box;
  ${space}
  ${width}
  ${color}
  ${flex}
  ${alignSelf}
  ${textAlign}
`

AnimatedBox.displayName = 'AnimatedBox'

export const Flex = styled('div')`
  display: flex;
  ${space}
  ${flexWrap}
  ${flexDirection}
  ${alignItems}
  ${textAlign}
  ${justifyContent}
`

Flex.displayName = 'Flex'

export const AnimatedFlex = styled(animated.div)`
  display: flex;
  ${space}
  ${flexWrap}
  ${flexDirection}
  ${alignItems}
  ${textAlign}
  ${justifyContent}
`

AnimatedFlex.displayName = 'AnimatedFlex'

export const Button = styled('button')`
  cursor:pointer;
  border-radius: 1000rem;
  border: none;
  font-weight: 700;
  font-size: 1.25rem;
  ${space}
  ${width}
  ${color}
`

Button.displayName = 'Button'


export const AnimatedButtonLink = styled(animated(Link))`
  cursor:pointer;
  border-radius: 1000rem;
  border: none;
  font-weight: 700;
  font-size: 1.25rem;
  text-decoration:none;
  ${space}
  ${width}
  ${color}
  width: fit-content;
  display: inline-block;
  background: ${props => props.theme.colors.primary};
  color:${props => props.theme.colors.black};
  cursor:pointer;
  transition: ease 0.3s;
   &:hover {
    box-shadow: 0 5px 5px #c6ceb157;
    background:${props => props.theme.colors.bleuflash};  
    transform: translateY(-5px);
    color:${props => props.theme.colors.black};
    text-decoration:none;
  }
  &:focus {
    outline: none;
    box-shadow:  0 3px 3px #c6ceb157;
    text-decoration:none;
  }

   /*  a { 
      text-decoration:none;
      color:${props => props.theme.colors.black}; 
      &:hover {text-decoration:none;color:${props => props.theme.colors.white}; }
    }*/
  
`

Button.displayName = 'AnimatedButtonLink'


export const AnimatedButton = styled(animated.button)`
  border-radius: 1000rem;
  border: none;
  font-weight: 700;
  font-size: 1.25rem;
  ${space}
  ${width}
  ${color}
`

Button.displayName = 'AnimatedButton'
