import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import {GatsbyImage } from "gatsby-plugin-image"
import LogoSVG from "./logosvg.js" 

/* fixed pour logo header , fluid pour le reste*/
const Logo = ({fixed, fluid, height}) => {
const data = useStaticQuery(
  graphql`
    query {
    file(relativePath: { eq: "logo 323.png" }) {
        childImageSharp {
          gatsbyImageData(
            height: 77
            placeholder: BLURRED
          )
        }
      }
    }
  `
  )

return ( 
           <>
             { fixed && <LogoSVG height={height}/>
 }
             { fluid && <GatsbyImage image={data.file.childImageSharp.gatsbyImageData}  alt="Parc Casse Noisette"/> }
           </>
      )
     
}

export default Logo

