module.exports = {
  pathPrefix: '/', // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "portfolio"

  siteTitle: 'Parc Casse Noisette', // Navigation and Site Title
  siteTitleAlt: 'Parc Casse Noisette', // Alternative Site title for Seo
  siteTitleShort: 'Parc Casse Noisette', // short_name for manifest
  siteHeadline: 'Découvrez un univers hors du temps !', // Headline for schema.org JSONLD
  siteUrl: 'https://www.parc-casse-noisette.com', // Domain of your site. No trailing slash!
  siteLanguage: 'fr', // Language Tag on <html> element
  siteLogo: '/logos/logo.png', // Used for Seo and manifest
  siteDescription: 'Découvrez un univers hors du temps ! Votre tout nouveau parc de loisirs nature dans le Gard.',
  author: 'Parc Casse Noisette', // Author for schema.org JSONLD
  facebookPageID: 'parccassenoisette',
  userTwitter: '@parccassenoisette', // Twitter Username
  ogSiteName: 'Parc Casse Noisette', // Facebook Site Name
  ogLanguage: 'fr_FR', // og:language
  googleAnalyticsID: 'UA-XXXXXX-XX',
  // Manifest and Progress color
  themeColor: '#A3CD3B',
  backgroundColor: '#0d0d0d',
  tel: '+33 (0)7 81 14 98 06',
  email: 'info@parc-casse-noisette.com',
}