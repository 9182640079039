import React from 'react'
import { Link } from 'gatsby'
import { injectIntl, intlShape } from 'react-intl'


const locales = {fr:
    {
      path: 'fr',
      name: 'Français',
      default: true
    },
    en:
    {
      path: 'en',
      name: 'English'
    },
  }
const LocalizedLink = ({ to, intl: { locale }, ...props }) => {
  const path = locales[locale].default ? to : `/${locale}${to}`

  return <Link {...props} to={path} />
}

export default injectIntl(LocalizedLink)