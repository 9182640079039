import React from 'react'
import styled from 'styled-components'
import { graphql,/* Link,*/ useStaticQuery } from 'gatsby'
import { Box, Flex/*, Button*/ } from '../elements'
import { FaMobileAlt } from 'react-icons/fa';
import theme from '../../config/theme'
import LanguageSwitcher from './language-switcher'
import siteConfig from '../../config'

const Left = styled(Box)`
	text-align: left;
  padding-right: 1rem;
  @media (max-width: ${props => props.theme.breakpoints[1]}) {
    padding-right: 0;
    width:100%;
  }
  p {margin:0;}
`

const Right = styled(Box)`
	text-align: right;
  padding-left: 1rem;
  @media (max-width: ${props => props.theme.breakpoints[1]}) {
    padding-left: 0;
    display:none;
    
  }
`

const Phone = styled.span `
position:relative;
svg {
  position:absolute;
  left:-1.2rem;
}`

const MobileLanguageSwitcherWrapper = styled.div`
display:none;
@media (max-width: ${props => props.theme.breakpoints[1]}) {
  padding-left: .5rem;
  display:block;
}
`
/*
const PButton = styled(Button)`
  background: ${props => (props.color === 'white' ? 'black' : props.color)};
  background: ${props => props.theme.colors.primary};
  color: ${props => readableColor(props.color === 'white' ? 'black' : props.color)};
  color:${props => props.theme.colors.black};
`*/


const SubHeader = ({ color, locale }) => {
  const data = useStaticQuery(query)
  let localizedTopMessage = data.datoCmsBandeauTop._allMessageLocales.filter(item => item.locale === locale)
 
  return (

           <Flex flexWrap="nowrap"
                flexDirection={'row'}
               py={2}
               px={4}
                justifyContent="space-between" 
                alignItems="center"
                style={{'backgroundColor':theme.colors.secondary,'fontSize':'14px','color':'white'}}>
              	<Left dangerouslySetInnerHTML={{ __html: localizedTopMessage[0].value }} />
                <MobileLanguageSwitcherWrapper><LanguageSwitcher/></MobileLanguageSwitcherWrapper>
               <Right>
              	 	
              	 		 <a href={`https://www.facebook.com/${siteConfig.facebookPageID}`} target="blank" title="facebook">
              	 		
              	 		</a>
                     <Flex flexWrap="nowrap"
                flexDirection={'row'}
              
            
                alignItems="center">
                      <Phone><FaMobileAlt/> {siteConfig.tel}</Phone>
                     <LanguageSwitcher/> 
                    </Flex>
              	 	
               </Right>
            </Flex>
  )
}

export default SubHeader

const query = graphql`
  query subheaderQuery {
    datoCmsBandeauTop {
      _allMessageLocales {
        locale
        value
      }
    }
  }
`