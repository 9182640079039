import React from 'react'
import styled from 'styled-components'
import {useStaticQuery, graphql } from 'gatsby'
import Link from './localizedLink'
import Logo from "./logo"
import { Box, Flex } from '../elements'
import theme from '../../config/theme'
import Wave from '../elements/wave'
import siteConfig from '../../config'
import { FormattedMessage} from 'react-intl'


const Wrapper = styled(Flex)`
  z-index:1;
  position: relative;
  width:100%;
  padding-top:5rem;
  align-items: stretch;
  background-color:  ${props =>props.color};
  @media (max-width: ${props => props.theme.breakpoints[1]}) {
    padding-top:3rem;
  }
`

const WrapNavigation = styled(Flex)`
  flex-wrap:wrap;
`

const Navigation = styled(Flex)`

  color: ${props => props.theme.colors.black};
  box-sizing: border-box;
  a {padding:0.5rem 0;}
`



const Footer = ({  color=theme.colors.primary }) => {
  const data = useStaticQuery(query)

  return (
            <> 

           <Wrapper color={ color }
           		as="footer" 
              flexDirection="column" 
              alignItems="flex-start" 
              justifyContent= "flex-end"
            >
            <Wave orientation="top" fill="white"/>
          <WrapNavigation  
            px={[5, 6, 8, 10]}  
            pt={[4, 6, 8, 10]}
            pb={[10, 10, 12, 12]} 
            justifyContent="space-between"alignItems="flex-start"
             >
            <Navigation fontSize={1}  my={4} flexDirection="column"  alignItems="flex-start" 
           >
              <Link to="/activites"><FormattedMessage id="menu__activites"/></Link>
              <Link to="/groupes"><FormattedMessage id="menu__groupes"/> </Link>
              <Link to="/infos-pratiques"><FormattedMessage id="footer__infos-pratiques"/></Link>
              <Link to="/contact">Contact</Link>
              <Link to="/mentions-legales"><FormattedMessage id="footer__mentions-legales"/> </Link>
              <Link to="/protection-des-donnees"><FormattedMessage id="footer__data-protection"/></Link>
            </Navigation>
            
            <Logo fixed height="200px" alt="Parc Casse Noisette"/>
             <div>
              <div>
                <p>
                 281, Chemin de Lafèbre<br/>
                30760 St-Julien de Peyrolas<br/>
                France<br/>
                Tel : {siteConfig.tel}
                </p>
              </div>    
            </div>
           </WrapNavigation>
           
           <Wave orientation="bottom" fill="white"/>
          
          </Wrapper>
          <Box px={[5, 6, 8, 10]} fontSize={.5}  my={3} pb={3} >
             (c) <FormattedMessage id="footer__allrights"/> - Parc Casse Noisette - 2023 - <Link to="/credits"><FormattedMessage id="footer__credits"/></Link>
          </Box>
          </>
          
  )
}

export default Footer


export const query = graphql`
  query FooterQuery {
    reglementInterieur: file(sourceInstanceName: { eq: "pdf" }, name: { eq: "reglement-interieur-chatouilleurs-des-cimes" }) {
         publicURL
    } 
  }
`
