import React, {useState} from 'react'
import styled, { createGlobalStyle, ThemeProvider } from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'
import Link from './localizedLink'
import { /*config,*/ useSpring, animated } from 'react-spring'
import ScrollLock/*, { TouchScrollable } */from 'react-scrolllock'
import { HamburgerSpring } from 'react-animated-burgers'
import 'typeface-boogaloo'
import "@fontsource/neucha"
import { /*AnimatedBox, AnimatedFlex, AnimatedButton,*/ Box, Flex/*, Button*/ } from '../elements'
/*import Popup from './popup'*/

import theme from '../../config/theme'
import reset from '../styles/reset'
import Logo from './logo'
import SubHeader from './subheader'
import { Script } from "gatsby"

import Footer from './footer'
import Headroom from 'react-headroom'
import headroomCss from './headroom'
import {IntlProvider} from 'react-intl'
import { FormattedMessage} from 'react-intl'
// Messages
import en from '../translations/en.json';
import fr from '../translations/fr.json';
const messages = { en, fr }

const GlobalStyles = createGlobalStyle`

  *::before,
  *::after {
    box-sizing: border-box;
  }
  ::selection {
    color: white;
    background-color: #f6993f;
  }
  html {
    box-sizing: border-box;
    border: 0;
    margin: 0;
    
    h1, h2, h3, h4, h5, h6 {
      font-weight: ${theme.fontWeights.bold};
    }
    
    h1 {
      font-family:'boogaloo',sans-serif;
      font-weight:bold;
      font-size: ${theme.fontSizes[7]};
      text-transform:uppercase;
    /*  text-shadow: 0px 2px 40px #00000020, 0px 2px 5px #494992;*/
    }
    h2 {
      font-size: ${theme.fontSizes[4]};
      
    }
    h3 {
      font-size: ${theme.fontSizes[3]};
    }
    h4 {
      font-size: ${theme.fontSizes[2]};
    }
    h5 {
      font-size: ${theme.fontSizes[1]};
    }
    h6 {
      font-size: ${theme.fontSizes[0]};
    }
    
    @media (max-width: 600px) {
    
      h1 {
        font-size: ${theme.fontSizes[4]};
      
      }
      h2 {
        font-size: ${theme.fontSizes[3]};
        
      }
      h3 {
        font-size: ${theme.fontSizes[2]};
      }
      h4 {
        font-size: ${theme.fontSizes[1]};
      }
      h5 {
        font-size: ${theme.fontSizes[0]};
      }
      h6 {
        font-size: ${theme.fontSizes[0]};
      }
    }
  }
  body {
    border: 0;
    margin: 0;
    padding: 0;
    color: ${theme.colors.black};
    font-family: 'Neucha', '-apple-system', 'Roboto', 'Helvetica', 'Arial', sans-serif;
    letter-spacing: 0.1rem;
    /*background: white;*/
    font-size: 18px;
      @media (max-width: 600px) {
      font-size: 16px;
      }
  }

  #guidap-popups {
    position: relative;
    z-index: 9999;
  }

  a {
    transition: all 0.3s ease-in-out;
    color:inherit;
    text-decoration:none;
    &:hover,
    &:focus {
      color: ${theme.colors.secondary};
      text-decoration: underline ;
    }
  }

ul {
  list-style-type:none;
 /* margin: 0;*/
  padding: 0;
}

table th { background:${theme.colors.primary}; padding:1rem;}
table td { background : ${theme.colors.shade} ; text-align:center;}

@media (max-width: 600px) {
  table th { background:${theme.colors.primary}; padding:0.1rem;}
}

/* BOUTONS GUIDAP */

@media (max-width: ${props => props.theme.breakpoints[2]}) {
  /*.headerpanier {display:none;}*/
}

.gdp-scoped-ui.guidap-activity-reserve-button {
  display: inline-block;
}

main .guidap-activity-reserve-button { /* on centre le bouton dans son conteneur */
  text-align:center;
}

main .gdp-scoped-ui .g-button.button.style-primary {
    background: ${theme.colors.bleuflash};
    color:${theme.colors.black};
    cursor:pointer;
    transition: ease 0.3s;
    font-weight: 700;
    display:block;
    font-size: 1.25rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    font-family: 'Neucha','-apple-system','Roboto','Helvetica','Arial',sans-serif;
    letter-spacing:0.1rem;
}

main .gdp-scoped-ui .g-button.button.style-primary:not([disabled]):hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 5px #c6ceb157 ;
  background:${theme.colors.orange};  
  transform: translateY(-5px);
  color:${theme.colors.black};
}

/* Bouton reserver acitivté detail side bar */
.side-activity .gdp-scoped-ui.guidap-activity-reserve-button {
  display: block;
}
.side-activity .gdp-scoped-ui .g-button.button.style-primary {
  background: ${theme.colors.bleuflash};
  color:${theme.colors.black};
  font-weight:normal;
  padding: 1.25rem;
  margin-bottom: 1.25rem;
  width: 100%;
}

/* Bouton reserver header */
header .gdp-scoped-ui .g-button.button.style-primary {
  background: ${theme.colors.bleuflash};
  color:${theme.colors.black};
  cursor:pointer;
  transition: ease 0.3s;
  font-weight: 600;
  display:block;
  font-size: 1.25rem;
  
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 2rem;
  padding-right: 2rem;
  @media (max-width: 600px) {  
    font-size: 1rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  font-family: 'Neucha','-apple-system','Roboto','Helvetica','Arial',sans-serif;
  letter-spacing:0.1rem;
}

header .gdp-scoped-ui .g-button.button.style-primary:not([disabled]):hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 5px #c6ceb157 ;
  background:${theme.colors.orange};  
  transform: translateY(-5px);
  color:${theme.colors.black};
  font-weight: 700;
}


  ${headroomCss}
  ${reset}
  
`

const isPartiallyActive = ({ isPartiallyCurrent }) => {
  return isPartiallyCurrent ? { className: 'navlink-active navlink' } : { className: 'navlink' }
}

const PartialNavLink = (props) => (
  <Link getProps={isPartiallyActive} {...props}>
    {props.children}
  </Link>
)

/*

const PButtonOpenBookingForm = styled(AnimatedButton)`
font-weight:500;
 background: ${props => (props.color === 'white' ? 'black' : props.color)};
  background: ${props => props.theme.colors.secondary};
  color: ${props => readableColor(props.color === 'white' ? 'black' : props.color)};
  color:white;
  cursor:pointer;
  transition: ease 0.3s;
   &:hover {
    box-shadow: 0 5px 5px #c6ceb157;
    transform: translateY(-5px);
  }
  &:focus {
    outline: none;
    box-shadow:  0 3px 3px #c6ceb157;
  }
`*/

const Wrapper = styled.div`
  min-height:100vh;
  display:flex;
  flex-direction:column;
`

const Header = styled(Box)`
   position: relative;
  width:100%;
  background: ${props => props.theme.colors.primary};
  @media (max-width: ${props => props.theme.breakpoints[1]}) {
    position: relative;
    width: 100%;
    8/*background: ${props => props.bg};*/
  }
  /* hamburger button */
  [aria-label='Navigation'] {
     @media (min-width: ${props => props.theme.breakpoints[2]}) {
        display:none;
    }
  }

`

const NavTop = styled(Flex)`
  
  @media (max-width: ${props => props.theme.breakpoints[2]}) {
      display:none;
    }

  a.navlink {
    text-transform:uppercase;
    text-decoration: none;
    color: ${props => props.theme.colors.secondary};
    font-size: ${props => props.theme.fontSizes[1]};
    font-weight:700;
    line-height: 1.5;
    font-family: "Neucha",cursive;
    margin-left: ${props => props.theme.space[5]};
    &:hover,
    &:focus,
    &.navlink-active {
      color: ${props => props.theme.colors.black};
    }
  }
   
  .navlink {
    display: inline-block;
    position: relative;
    color: inherit;
     text-decoration: none ;
  }
  .navlink::after {
    content: '';
    position: absolute;
    width: 50%;
    transform: scaleX(0);
    height: 1px;
    bottom: -2px;
    left: 0;

    background-color: ${theme.colors.secondary};
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }
   .navlink:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
   
  }
  
`

/*
const Nav = styled(Flex)`
  a {
    text-transform:uppercase;
    text-decoration: none;
    color: ${props => readableColor(`${props.color}`)};
    font-size: ${props => props.theme.fontSizes[1]};
    line-height: 1.5;
     margin-left: ${props => props.theme.space[4]};
    &:hover,
    &:focus,
    &.navlink-active {
      color: ${props => props.theme.colors.primary};
    }

    @media (max-width: ${props => props.theme.breakpoints[2]}) {
      font-size: ${props => props.theme.fontSizes[1]};
      margin-left: ${props => props.theme.space[4]};
    }

    @media (max-width: ${props => props.theme.breakpoints[1]}) {
      font-size: ${props => props.theme.fontSizes[1]};
      margin-left: ${props => props.theme.space[3]};
    }

    @media (max-width: ${props => props.theme.breakpoints[0]}) {
      font-size: ${props => props.theme.fontSizes[0]};
      margin-left: ${props => props.theme.space[2]};
    }
  }
`*/

const Main = styled.main`
    position:relative;
    flex-grow:1; 
    display: flex;
    flex-direction:column;
    align-items: center;
    align-items: stretch;
    justify-content: center;

/*  @media (min-width: calc(${props => props.theme.breakpoints[2]} + 1px)) {
    grid-column-start: 2;
  }*/
`

/*
const PButton = styled(Button)`
  background: ${props => (props.color === 'white' ? 'black' : props.color)};
  background: ${props => props.theme.colors.primary};
  color: ${props => readableColor(props.color === 'white' ? 'black' : props.color)};
  color:${props => props.theme.colors.black};
`*/
/*MENU MOBILE*/
const MenuMobile = styled(animated.div)`
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  z-index:500;

`
const ContentMobile = styled(Flex)`
height:100%;
padding-top:168px;
box-sizing:border-box;

  nav {
    display:flex;
    flex-direction:column;
    align-content:center;
    justify-content:center;
    a {
      font-family: "Neucha",cursive;
      font-size:26px;
      text-transform:uppercase;
      margin:1rem;
    }
  }
`/*
const ButtonPopup = styled(animated.button)`
 background: ${props => props.theme.colors.primary};
  color: ${props => props.theme.colors.black};
  position:fixed;
  top:80%;
  right:0;
  border:0;
  padding:1em;
  padding-left:3rem;
  z-index:6000;
  cursor:pointer;
  border-radius: 0.25em 0px 0px 0.25em;
  &:hover svg {
    color: ${props => props.theme.colors.secondary};

  }

`*/


const Layout = ({ children, color, header, footer, pageContext }) => {
  //const [isBookingFormOpen, setBookingFormOpened] = useState(false)
 // const handleClick = (e) => window.GUIDAP.booking.call('bolleneaventure.guidap.co', 'pu8dkHrUlK1mGcSPazjA3fJbFVXDY4yT6ZsC?lang=fr', e)
  //const handleClick = (e) => console.log("clic à lier à guidap", e)
  
  

  //console.log("pageContext:", pageContext)
  const data = useStaticQuery(query)
  // Mobile Menu logic
  const [mobileNavOpen, setMobileNavOpen] = useState(false) // closed by default
  const MenuAnimation = useSpring({
  native: true,
  to: { opacity: mobileNavOpen ? 1 : 0, transform: mobileNavOpen ? 'translate3d(0,0,0)' : 'translate3d(0,-100%, 0)', backgroundColor: mobileNavOpen ? theme.colors.primary : theme.colors.black},
  })

  /*const [isPopupOpen, setPopupIsOpen] = useState(false);
  const ButtonPopupAnimation = useSpring({
  native: true,
  to: { opacity: isPopupOpen ? 0.2 : 1,
        transform: isPopupOpen ? 'translate3d(100%,0,0)' : 'translate3d(0%,0, 0)'
      }
  })*/


let localizedNavigation = data.navigation.edges.filter(item => item.node.language === pageContext.locale);

  return (
    <IntlProvider locale={pageContext.locale} key={pageContext.locale}  messages={messages[pageContext.locale]}  defaultLocale="fr" >

    <ThemeProvider theme={theme}>

      <>
        <Script  
          data-token="4p8GouE2DqtTKLUvJNAjd0ImrBPlsyVF6xcb"
          data-lang={pageContext.locale}
          data-currency="EUR"
          src="https://cart.guidap.net/v1/"
          crossorigin="anonymous"
        />
        <GlobalStyles/>
        <Wrapper>
            <>
            <ScrollLock  isActive={mobileNavOpen} >
              <MenuMobile style={ MenuAnimation}>

                <ContentMobile flexDirection={['column']}
                    alignItems={['center']}
                    justifyContent="center"
                    pb={[6]}
                    >
               
                  <nav>
                    {localizedNavigation.map(({ node: item }) => (
                    <PartialNavLink to={item.link} key={item.name} onClick= {(e) => setMobileNavOpen(!mobileNavOpen)}
                    >
                      {item.name}
                    </PartialNavLink>
                    ))}
                  </nav>
                      <guidap-cart-button></guidap-cart-button> 
                </ContentMobile>    
              </MenuMobile>
            </ScrollLock>

            <Headroom >
            <SubHeader locale={pageContext.locale}/>
            <Header bg={color} as="header" m={0} py={[2]}   px={[6, 6, 8, 10]}  >

                <Flex
                  flexWrap="nowrap"
                  flexDirection={['row', 'row', 'row', 'row']}
                  alignItems={['center', 'center', 'center', 'center']}
                  justifyContent="space-between"
                >
                  <Link to="/" aria-label="Accueil"><Logo fixed/></Link>
                    <NavTop  color={color}>
                    
                    {localizedNavigation.map(({ node: item }) => (
                    <PartialNavLink to={item.link} key={item.name}>
                      {item.name}
                    </PartialNavLink>
                    ))} 
                    </NavTop>
                    <div style={{'display':'flex'}}>
                      <guidap-activity-reserve-button activity-uuid="AI9dcYwpMfv8GDtgoTBFE7Jy4hLbxjsUerSZ"></guidap-activity-reserve-button>
                     <div className="headerpanier"><guidap-cart-button></guidap-cart-button></div> 
                    </div>
                    
                  {/*   <AnimatedBox py={[2, 2, 3, 3]}  >

                    <PButtonOpenBookingForm color="red" py={4} px={8}   
                        onClick={ handleClick } >
                Réserver
                  </PButtonOpenBookingForm>
                  </AnimatedBox>*/}
                
                  <HamburgerSpring isActive={mobileNavOpen} toggleButton={() => {setMobileNavOpen(!mobileNavOpen)}} buttonStyle={{ outline: "none" }} barColor={theme.colors.secondary} aria-label="Navigation"/>

                </Flex>
              </Header>
            </Headroom>
          </>
        
        
          <Main>{children}</Main>
          
           <Footer />
     
        </Wrapper>


      </>
      
    </ThemeProvider>
</IntlProvider>
  )
}

export default Layout

const query = graphql`
  query LayoutQuery {
    navigation: allNavigationYaml {
      edges {
        node {
          name
          link
          language
        }
      }
    }
  }
`
